import React from 'react';

import { TbCategory, TbLogin2 } from 'react-icons/tb';
import { IoMdContacts, IoIosSettings } from 'react-icons/io';
import { SiCrowdsource } from 'react-icons/si';
import { FaClipboardList, FaLanguage } from 'react-icons/fa';
import { TfiLayoutSlider } from 'react-icons/tfi';
import { HiOutlineRefresh } from 'react-icons/hi';
import { BiNavigation, BiColorFill, BiSolidPurchaseTagAlt } from 'react-icons/bi';
import { RiContactsLine, RiStockLine } from 'react-icons/ri';
import { HiOutlineExclamationCircle } from 'react-icons/hi2';
import { GiBookshelf, GiLouvrePyramid } from 'react-icons/gi';
import { FiShoppingBag, FiEdit, FiPieChart, FiBarChart, FiCreditCard } from 'react-icons/fi';
import {
  MdOutlinePriceChange,
  MdLanguage,
  MdOutlineCategory,
  MdOutlinePlayLesson,
  MdOutlineSupervisorAccount,
} from 'react-icons/md';
import {
  BsKanban,
  BsBarChart,
  BsBoxSeam,
  BsCurrencyDollar,
  BsShield,
  BsPostcard,
} from 'react-icons/bs';
import {
  AiOutlineCalendar,
  AiOutlineAreaChart,
  AiOutlineBarChart,
  AiOutlineStock,
} from 'react-icons/ai';

import avatar from './avatar.jpg';
import avatar2 from './avatar2.jpg';
import avatar3 from './avatar3.png';
import avatar4 from './avatar4.jpg';
import product5 from './product5.jpg';
import product6 from './product6.jpg';
import product7 from './product7.jpg';

export const links = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'dashboard',
        icon: <FiShoppingBag />,
      },
    ],
  },

  {
    title: 'Pages',
    links: [
      {
        name: 'navigation',
        icon: <BiNavigation />,
      },
      {
        name: 'categories',
        icon: <TbCategory />,
      },
      {
        name: 'subcategories',
        icon: <MdOutlineCategory />,
      },
      {
        name: 'position status',
        icon: <BsPostcard />,
      },
      {
        name: 'language',
        icon: <MdLanguage />,
      },
      {
        name: 'user-language',
        icon: <FaLanguage />,
      },
      {
        name: 'sources',
        icon: <SiCrowdsource />,
      },
      {
        name: 'courses',
        icon: <MdOutlinePlayLesson />,
      },
      {
        name: 'books categories',
        icon: (
          <>
            <GiBookshelf />
            <TbCategory />
          </>
        ),
      },
      {
        name: 'books subcategories',
        icon: (
          <>
            <GiBookshelf />
            <MdOutlineCategory />
          </>
        ),
      },
      {
        name: 'books',
        icon: <GiBookshelf />,
      },
      {
        name: 'swiper slider',
        icon: <TfiLayoutSlider />,
      },
      {
        name: 'price',
        icon: <MdOutlinePriceChange />,
      },
      {
        name: 'employees',
        icon: <IoMdContacts />,
      },
      {
        name: 'users',
        icon: <RiContactsLine />,
      },
      {
        name: 'orders',
        icon: <FaClipboardList />,
      },
      {
        name: 'about',
        icon: <HiOutlineExclamationCircle />,
      },
      {
        name: 'settings',
        icon: <IoIosSettings />,
      },
    ],
  },
  {
    title: 'Info Desk',
    links: [
      {
        name: 'purchases',
        icon: <BiSolidPurchaseTagAlt />,
      },
      {
        name: 'visits',
        icon: <TbLogin2 />,
      },
    ],
  },
  {
    title: 'Apps',
    links: [
      {
        name: 'calendar',
        icon: <AiOutlineCalendar />,
      },
      {
        name: 'kanban',
        icon: <BsKanban />,
      },
      {
        name: 'editor',
        icon: <FiEdit />,
      },
      {
        name: 'color-picker',
        icon: <BiColorFill />,
      },
    ],
  },
  {
    title: 'Charts',
    links: [
      {
        name: 'line',
        icon: <AiOutlineStock />,
      },
      {
        name: 'area',
        icon: <AiOutlineAreaChart />,
      },

      {
        name: 'bar',
        icon: <AiOutlineBarChart />,
      },
      {
        name: 'pie',
        icon: <FiPieChart />,
      },
      {
        name: 'financial',
        icon: <RiStockLine />,
      },
      {
        name: 'color-mapping',
        icon: <BsBarChart />,
      },
      {
        name: 'pyramid',
        icon: <GiLouvrePyramid />,
      },
      {
        name: 'stacked',
        icon: <AiOutlineBarChart />,
      },
    ],
  },
];

export const cartData = [
  {
    image: product5,
    name: 'butterscotch ice-cream',
    category: 'Milk product',
    price: '$250',
  },
  {
    image: product6,
    name: 'Supreme fresh tomato',
    category: 'Vegetable Item',
    price: '$450',
  },
  {
    image: product7,
    name: 'Red color candy',
    category: 'Food Item',
    price: '$190',
  },
];

export const chatData = [
  {
    image: avatar2,
    message: 'Roman Joined the Team!',
    desc: 'Congratulate him',
    time: '9:08 AM',
  },
  {
    image: avatar3,
    message: 'New message received',
    desc: 'Salma sent you new message',
    time: '11:56 AM',
  },
  {
    image: avatar4,
    message: 'New Payment received',
    desc: 'Check your earnings',
    time: '4:39 AM',
  },
  {
    image: avatar,
    message: 'Jolly completed tasks',
    desc: 'Assign her new tasks',
    time: '1:12 AM',
  },
];

export const earningData = [
  {
    icon: <MdOutlineSupervisorAccount />,
    amount: '39,354',
    percentage: '-4%',
    title: 'Customers',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    pcColor: 'red-600',
  },
  {
    icon: <BsBoxSeam />,
    amount: '4,396',
    percentage: '+23%',
    title: 'Products',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',
    pcColor: 'green-600',
  },
  {
    icon: <FiBarChart />,
    amount: '423,39',
    percentage: '+38%',
    title: 'Sales',
    iconColor: 'rgb(228, 106, 118)',
    iconBg: 'rgb(255, 244, 229)',

    pcColor: 'green-600',
  },
  {
    icon: <HiOutlineRefresh />,
    amount: '39,354',
    percentage: '-12%',
    title: 'Refunds',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
    pcColor: 'red-600',
  },
];

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
];

export const userProfileData = [
  {
    icon: <BsCurrencyDollar />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
  },
  {
    icon: <BsShield />,
    title: 'My Inbox',
    desc: 'Messages & Emails',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
  },
  {
    icon: <FiCreditCard />,
    title: 'My Tasks',
    desc: 'To-do and Daily Tasks',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',
  },
];
