import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import {
  Ecommerce,
  Navigation,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  Categories,
  BooksCategories,
  Subcategories,
  SubcategoriesBooks,
  PositionStatus,
  Sources,
  Courses,
  Language,
  SwiperSlider,
  Books,
  About,
  Purchase,
  Settings,
  UserLanguage,
  UserLanguageNew,
  Visits,
} from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';
import NavigationNew from './pages/Navigation/NavigationNew';
import CategoriesNew from './pages/Categories/CategoriesNew';
import SubcategoriesNew from './pages/Subcategories/SubcategoriesNew';
import PositionStatusNew from './pages/PositionStatus/PositionStatusNew';
import LanguageNew from './pages/Language/LanguageNew';
import SourcesNew from './pages/Sources/SourcesNew';
import CoursesNew from './pages/Course/CoursesNew';
import BookCategoriesNew from './pages/BookCategories/BookCategoriesNew';
import BookSubcategoriesNew from './pages/BookSubcategories/BookSubcategoriesNew';
import BooksNew from './pages/Books/BooksNew';
import SwiperSliderNew from './pages/SwiperSlide/SwiperSliderNew';
import CustomersNew from './pages/User/UserNew';
import Order from './pages/Order/Order';
import OrderNew from './pages/Order/OrderNew';
import Price from './pages/Price/Price';
import PriceNew from './pages/Price/PriceNew';
import AuthProvider from './components/AuthProvider/AuthProvider';
import { authenticationApi } from './api/services/authenticationApi';
import { setUser } from './redux/features/userSlice';
import { useAppDispatch } from './redux/app/hooks';
import AboutNew from './pages/About/AboutNew';

const App = () => {
  const dispatch = useAppDispatch();

  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  useEffect(() => {
    const getUserData = async () => {
      const { userUuid } = JSON.parse(
        localStorage.getItem('sb_admin_user') || sessionStorage.getItem('sb_admin_user')!
      );
      const { data } = await authenticationApi.getUser(userUuid as string);
      dispatch(setUser(data?.user));
    };

    getUserData().catch((error) => console.error('error', error));
  }, []);

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <AuthProvider>
          <div className="flex relative dark:bg-main-dark-bg">
            <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
              {
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: '50%' }}
                  className="text-3xl text-white p-2 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiSettings size={18} />
                </button>
              }
            </div>
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar />
              </div>
            )}
            <div
              className={
                activeMenu
                  ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-[78%] ml-auto mr-[15px] '
                  : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
              }
            >
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                <Navbar />
              </div>
              <div>
                {themeSettings && <ThemeSettings />}

                <Routes>
                  {/* dashboard  */}
                  <Route path="/" element={<Ecommerce />} />
                  <Route path="/dashboard" element={<Ecommerce />} />

                  {/* pages  */}
                  <Route path="/navigation" element={<Navigation />} />
                  <Route path="/navigation/new" element={<NavigationNew />} />
                  <Route path="/navigation/:uuid" element={<NavigationNew />} />

                  <Route path={'/categories'} element={<Categories />} />
                  <Route path={'/categories/new'} element={<CategoriesNew />} />
                  <Route path="/categories/:uuid" element={<CategoriesNew />} />

                  <Route path={'/subcategories'} element={<Subcategories />} />
                  <Route path={'/subcategories/new'} element={<SubcategoriesNew />} />
                  <Route path={'/subcategories/:uuid'} element={<SubcategoriesNew />} />

                  <Route path={'/books categories'} element={<BooksCategories />} />
                  <Route path={'/books categories/new'} element={<BookCategoriesNew />} />
                  <Route path={'/books categories/:uuid'} element={<BookCategoriesNew />} />

                  <Route path={'/books subcategories'} element={<SubcategoriesBooks />} />
                  <Route path={'/books subcategories/new'} element={<BookSubcategoriesNew />} />
                  <Route path={'/books subcategories/:uuid'} element={<BookSubcategoriesNew />} />

                  <Route path={'/swiper slider'} element={<SwiperSlider />} />
                  <Route path={'/swiper slider/new'} element={<SwiperSliderNew />} />
                  <Route path={'/swiper slider/:uuid'} element={<SwiperSliderNew />} />

                  <Route path={'/position status'} element={<PositionStatus />} />
                  <Route path={'/position status/new'} element={<PositionStatusNew />} />
                  <Route path={'/position status/:uuid'} element={<PositionStatusNew />} />

                  <Route path={'/price'} element={<Price />} />
                  <Route path={'/price/new'} element={<PriceNew />} />
                  <Route path={'/price/:uuid'} element={<PriceNew />} />

                  <Route path={'/language'} element={<Language />} />
                  <Route path={'/language/new'} element={<LanguageNew />} />
                  <Route path={'/language/:uuid'} element={<LanguageNew />} />

                  <Route path={'/sources'} element={<Sources />} />
                  <Route path={'/sources/new'} element={<SourcesNew />} />
                  <Route path={'/sources/:uuid'} element={<SourcesNew />} />

                  <Route path={'/courses'} element={<Courses />} />
                  <Route path={'/courses/new'} element={<CoursesNew />} />
                  <Route path={'/courses/:uuid'} element={<CoursesNew />} />

                  <Route path={'/books'} element={<Books />} />
                  <Route path={'/books/new'} element={<BooksNew />} />
                  <Route path={'/books/:uuid'} element={<BooksNew />} />

                  <Route path="/employees" element={<Employees />} />

                  <Route path="/users" element={<Customers />} />
                  <Route path={'/users/:uuid'} element={<CustomersNew />} />
                  <Route path="/users" element={<CustomersNew />} />

                  <Route path={'/orders'} element={<Order />} />
                  <Route path={'/orders/new'} element={<OrderNew />} />
                  <Route path={'/orders/:uuid'} element={<OrderNew />} />

                  <Route path={'/settings'} element={<Settings />} />

                  <Route path={'/user-language'} element={<UserLanguage />} />
                  <Route path={'/user-language/new'} element={<UserLanguageNew />} />
                  <Route path={'/user-language/:uuid'} element={<UserLanguageNew />} />

                  <Route path={'/purchases'} element={<Purchase />} />
                  {/* <Route path={'/purchases/new'} element={<PurchaseNew />} /> */}
                  {/* <Route path={'/purchase/:uuid'} element={<PurchaseNew />} /> */}

                  <Route path={'/visits'} element={<Visits />} />

                  <Route path={'/about'} element={<About />} />
                  <Route path={'/about/new'} element={<AboutNew />} />
                  <Route path={'/about/:uuid'} element={<AboutNew />} />

                  {/* apps  */}
                  <Route path="/kanban" element={<Kanban />} />
                  <Route path="/editor" element={<Editor />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/color-picker" element={<ColorPicker />} />

                  {/* charts  */}
                  <Route path="/line" element={<Line />} />
                  <Route path="/area" element={<Area />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/financial" element={<Financial />} />
                  <Route path="/color-mapping" element={<ColorMapping />} />
                  <Route path="/pyramid" element={<Pyramid />} />
                  <Route path="/stacked" element={<Stacked />} />
                </Routes>
              </div>
              {/* <Footer /> */}
            </div>
          </div>
        </AuthProvider>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
};

export default App;
