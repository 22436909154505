import { AxiosResponse } from 'axios';

import instance from '../axios';
import { premiumType, userType, visitInformationType } from '../../../types/types';

interface getUserByUuidType {
  user: userType;
}

interface getUsersVisitsType {
  success: boolean;
  statusCode: number;
  visitInformation: visitInformationType[];
  totalCount: number;
}

interface getUsersRegistrationType {
  success: boolean;
  statusCode: number;
  registrationInformation: visitInformationType[];
  totalCount: number;
}

interface getUsersPremiumType {
  success: boolean;
  statusCode: number;
  premiumInformation: premiumType;
  totalCount: number;
}

interface allUsersType {
  users: userType[];
  totalCount: number;
}

interface userApiType {
  getUser: () => Promise<AxiosResponse<userType>>;
  getAllUsers: (
    pageSize?: number,
    currentPage?: number,
    searchValue?: string
  ) => Promise<AxiosResponse<allUsersType>>;
  getUserByUuid: (userUuid: string) => Promise<AxiosResponse<getUserByUuidType>>;
  getUsersThirtyDaysVisits: () => Promise<AxiosResponse<getUsersVisitsType>>;
  getUsersThirtyDaysRegistrations: () => Promise<AxiosResponse<getUsersRegistrationType>>;
  getUsersPremiumNotPremium: () => Promise<AxiosResponse<getUsersPremiumType>>;
  getUsersDaysVisits: (
    pageSize?: number,
    currentPage?: number,
    searchValue?: string
  ) => Promise<AxiosResponse<getUsersVisitsType>>;
  postUserUpdatedData: (data: any) => Promise<AxiosResponse<any>>;
  updateUser: (uuid: string, data: any) => Promise<AxiosResponse<any>>;
  deleteUser: (uuid: string) => Promise<AxiosResponse<any>>;
}

export const userApi: userApiType = {
  getUser: async () => instance.get(`v1/user/get-user`),
  getAllUsers: async (pageSize, currentPage, searchValue) =>
    instance.get(`v1/user/get-all`, { params: { pageSize, currentPage, searchValue } }),
  getUserByUuid: async (userUuid) => instance.get(`v1/user/get-by-uuid/${userUuid}`),
  postUserUpdatedData: async (data) => instance.post(`v1/user/update`, data),
  updateUser: async (uuid, data) => instance.post(`/v1/user/update/${uuid}`, data),
  deleteUser: async (uuid) => instance.delete(`/v1/user/delete/${uuid}`),
  getUsersThirtyDaysVisits: async () => instance.get(`/v1/info/get-thirty-days-visits`),
  getUsersDaysVisits: async (pageSize, currentPage, searchValue) =>
    instance.get(`/v1/info/get-days-visits`, { params: { pageSize, currentPage, searchValue } }),
  getUsersThirtyDaysRegistrations: async () =>
    instance.get(`/v1/info/get-thirty-days-registrations`),
  getUsersPremiumNotPremium: async () => instance.get(`/v1/info/get-user-premium-not-premium`),
};
