import React, { useState } from 'react';

import moment from 'moment';
import { Page } from '../index';
import { useVisitsTableColumns } from './useVisitsTableColumns';
import Loading from '../../components/Loading/Loading';
import { useGetVisits } from '../../hooks/ReactQuery/Visits/useGetVisits';
import useQueryParams from '../../helpers/useQueryParams';

const Visits = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetVisits(pageSize, currentPage, searchValue);

  if (isFetching && isPending) return <Loading />;

  const formattedData: any = data?.visitInformation?.map((elem) => ({
    id: elem?.uuid,
    ip: elem?.ip,
    visitDate: moment(elem?.createdAt).format('DD/MM/YYYY'),
    userUuid: elem.userUuid,
    clientCountry: elem?.clientCountry,
    clientRegion: elem?.clientRegion,
    clientCity: elem?.clientCity,
    clientTimezone: elem?.clientTimezone,
  }));

  return (
    <Page
      title="Visits"
      description="Visits info"
      columns={useVisitsTableColumns}
      formattedData={formattedData}
      searchKey="ip"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default Visits;
